<!-- eslint-disable vue/valid-v-slot -->
<template>
  <v-container>
    <h2 data-cy="nbOnlineUsers">
      Online Users ({{ onlineUser[0]?.nbOnlineUsers }}) -
      {{ onlineUser[0]?.maxNbOnlineUsers }} Max Online Users
    </h2>
    <v-data-table
      v-if="shown"
      :headers="headersOnlineUsers"
      :items="onlineUsers"
      density="compact"
      item-key="_id"
    >
    </v-data-table>
    <v-divider class="my-3"></v-divider>
    <h2>Registred Users</h2>
    <v-data-table
      v-if="shown"
      :headers="headersRegistredUsers"
      :items="registredUsers"
      density="compact"
      item-key="_id"
    >
      <template #item.actions="{ item }">
        <v-icon
          class="me-2"
          size="small"
          icon="$sendEmail"
          @click="enroll(item)"
        ></v-icon>
        <v-icon
          class="me-2"
          size="small"
          icon="$delete"
          @click="onClickDelete(item)"
        >
        </v-icon> </template
    ></v-data-table>

    <v-divider class="my-3"></v-divider>
    <h2>Enrolled Users</h2>
    <v-data-table
      v-if="shown"
      :headers="headersEnrolledUsers"
      :items="enrolledUsers"
      density="compact"
      item-key="_id"
    >
      <template #item.actions="{ item }">
        <v-icon
          class="me-2"
          size="small"
          icon="$sendEmail"
          @click="enroll(item)"
        ></v-icon> </template
    ></v-data-table>

    <v-divider class="my-3"></v-divider>
    <h2>Categories</h2>

    <v-row no-gutters>
      <v-col cols="4" md="4"> Total </v-col>
      <v-col cols="4" md="4"> Effacées </v-col>
      <v-col cols="4" md="4"> Actives</v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="4" md="4">
        <v-chip>{{ adminStats.totalCategories }}</v-chip>
      </v-col>
      <v-col cols="4" md="4">
        <v-chip>{{ adminStats.deletedCategories }}</v-chip>
      </v-col>
      <v-col cols="4" md="4">
        <v-chip>{{ adminStats.activeCategories }}</v-chip>
      </v-col>
    </v-row>

    <v-divider class="my-3"></v-divider>
    <h2>Events</h2>
    <v-row no-gutters>
      <v-col cols="3" md="3"> Total </v-col>
      <v-col cols="3" md="3"> Effacés </v-col>
      <v-col cols="3" md="3"> Actifs </v-col>
      <v-col cols="3" md="3"> Futurs </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="3" md="3">
        <v-chip>{{ adminStats.totalEvents }}</v-chip>
      </v-col>
      <v-col cols="3" md="3">
        <v-chip>{{ adminStats.deletedEvents }}</v-chip>
      </v-col>
      <v-col cols="3" md="3">
        <v-chip>{{ adminStats.activeEvents }}</v-chip>
      </v-col>
      <v-col cols="3" md="3">
        <v-chip>{{ adminStats.futureEvents }}</v-chip>
      </v-col>
    </v-row>

    <v-divider class="my-3"></v-divider>
    <h2>Users</h2>
    <v-row no-gutters>
      <v-col cols="4" md="4"> Total </v-col>
      <v-col cols="4" md="4"> Actifs </v-col>
      <v-col cols="4" md="4"> Max Actifs </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="4" md="4">
        <v-chip>{{ adminStats.totalUsers }}</v-chip>
      </v-col>
      <v-col cols="4" md="4">
        <v-chip>{{ onlineUser[0]?.nbOnlineUsers }}</v-chip>
      </v-col>
      <v-col cols="4" md="4">
        <v-chip>{{ onlineUser[0]?.maxNbOnlineUsers }}</v-chip>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { onBeforeMount, computed, ref, onMounted } from 'vue';
import { subscribe, autorun } from 'vue-meteor-tracker';
import Admin from '/imports/api/collections/admin';
import { watch } from 'vue';
import { useNotifier } from 'vuetify-notifier';
import { useStore } from 'vuex';
import log from 'loglevel';

const $notifier = useNotifier();
const store = useStore();

let onlineUsers = ref([]);
let registredUsers = ref([]);
let enrolledUsers = ref([]);
let adminStats = ref(0);
let headersOnlineUsers = [
  {
    title: 'Id',
    align: 'start',
    sortable: false,
    key: '_id'
  },
  { title: 'FirstName', key: 'firstName' },
  { title: 'LastName', key: 'lastName' },
  { title: 'Email', key: 'email' }
];

let headersRegistredUsers = [
  {
    title: 'Id',
    align: 'start',
    sortable: false,
    key: '_id'
  },
  { title: 'FirstName', key: 'firstName' },
  { title: 'LastName', key: 'lastName' },
  { title: 'Email', key: 'email' },

  { title: 'Actions', key: 'actions', sortable: false }
];

let headersEnrolledUsers = [
  {
    title: 'Id',
    align: 'start',
    sortable: false,
    key: '_id'
  },
  { title: 'FirstName', key: 'firstName' },
  { title: 'LastName', key: 'lastName' },
  { title: 'Email', key: 'email' },

  { title: 'Actions', key: 'actions', sortable: false }
];

subscribe('nbonlineusers');
const onlineUser = autorun(() => Admin.find({}).fetch()).result;

const shown = computed(() => {
  return onlineUsers.value?.length > 0;
});

watch(onlineUser, async () => {
  try {
    onlineUsers.value = await Meteor.callAsync('get.onlineusers', {});
  } catch (error) {
    log.error('Error getting online users', error);
  }
});

onBeforeMount(async () => {
  try {
    onlineUsers.value = await Meteor.callAsync('get.onlineusers', {});
  } catch (error) {
    log.error('Error getting online users', error);
  }
  try {
    registredUsers.value = await Meteor.callAsync('get.registredusers', {});
  } catch (error) {
    log.error('Error getting registred users', error);
  }

  try {
    enrolledUsers.value = await Meteor.callAsync('get.enrolledusers', {});
  } catch (error) {
    log.error('Error getting enrolled users', error);
  }
  try {
    adminStats.value = await Meteor.callAsync('get.AdminStats', {});
  } catch (error) {
    log.error('Error getting admin Stats', error);
  }
});

onMounted(() => {
  log.info('ViewAdmin mounted');
  store.commit('setAppNavTitle', 'Admin');
});

function enroll(item) {
  log.info('Enroll', item);
  Meteor.callAsync('enrolluser', { userId: item._id }).then(async () => {
    try {
      registredUsers.value = await Meteor.callAsync('get.registredusers', {});
    } catch (error) {
      log.error('Error getting registred users', error);
    }

    try {
      enrolledUsers.value = await Meteor.callAsync('get.enrolledusers', {});
    } catch (error) {
      log.error('Error getting enrolled users', error);
    }
    $notifier.alertSuccess('Enroll OK');
  });
}

function onClickDelete(item) {
  log.info('Delete', item);
  Meteor.callAsync('deleteuser', { userId: item._id }).then(async () => {
    try {
      registredUsers.value = await Meteor.callAsync('get.registredusers', {});
    } catch (error) {
      log.error('Error getting registred users', error);
    }

    $notifier.alertSuccess('Delete OK');
  });
}
</script>
