import { Mongo } from 'meteor/mongo';

import { z } from 'zod';

const adminSchema = z.object({
  nbOnlineUsers: z.number(),
  deleted: z.boolean(),
  maxNbOnlineUsers: z.number(),
  deletedAt: z.date().optional(),
  updatedAt: z.date().optional()
});

const Admin = new Mongo.Collection('admin', {
  idGeneration: 'MONGO'
});

if (Meteor.isServer) {
  Admin.withSchema(adminSchema);
  Admin.withDates();
  Admin.withUser();
}

export default Admin;
